.page-title {
	position: relative;
	z-index: 70000;
	padding: 6em 0 0;
	text-shadow: 0 1px 2px rgba(0,0,0,0.4);
	color: $white;
	border-bottom: 5px solid $white;

	@include gdfancy-bg($gray-100);


	h1 {
		margin: 0;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.7);
		text-transform: uppercase;
		letter-spacing: 0.125rem;
		line-height: 1em;
		small {
			display: block;
			font-size: 70%;
			line-height: 1em;
			letter-spacing: 0.15rem;
		}
	}

	.title-wrap {
		padding: 0.9em 0 0.5em;
		border-top: 3px solid rgba(0, 0, 0, 0.4);
		background-color: rgba($color-primary, 0.7);
	}

	@include media-breakpoint-up(sm) { padding-top: 9em; }
	@include media-breakpoint-up(md) { padding-top: 11em; }
	@include media-breakpoint-up(lg) { padding-top: 13em; }
	@include media-breakpoint-up(xl) { padding-top: 15em; }

	.post & {
		h1 { max-width: 730px; }
	}
	
}