$reviews-bg: 							$white; //$color-review !default;
$reviews-color: 						inherit; //$white !default;

.reviews {

	border-top: 0.25em solid $white;

	.block-title {
		margin-top: 0;
		font-weight: normal;
		text-align: center;
	}

	.btn_review {
		font-size: 1.1rem;
		text-shadow: 0 0.07em 0 rgba(0,0,0,0.25);
	}

	.review {
		margin-bottom: 1em;
	}

	@include media-breakpoint-up(md) {
		.reviews-wrapper {
			overflow: hidden;
			.review {
				float: left;
				width: 49%;
				padding: 2em;
				background-size: auto 95%;
				&:nth-child(odd) { clear: both }
				&:nth-child(even) { float: right }
				img { max-width: 170px; margin: 0 auto 0.75em }
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.reviews-wrapper .review img { max-width: 200px; margin-bottom: 1em; }
	}

	@include media-breakpoint-up(xl) {
		.reviews-wrapper .review { background-size: auto 100%; }
	}

}

	.review {
		//border-sizing: border-box; 
		margin: 0 auto 2em;
		padding: 2em;
		font-size: 1.1rem;
		border-radius: 6px;
		border: 5px solid rgba(0,0,0,0.12);
		background: $white url("/_/images/icons/comment-edit.svg") -5% center no-repeat;
		background-size: auto 95%;
		box-shadow: 0 0.25em 1.75em -0.75em rgba(0,0,0,0.7);

		img {
			max-width: 170px;
			margin: 0 auto 0.75em;
		}

		cite {
			display: block;
			padding: 0.25em 0.75em;
			font-size: 0.9rem;
			font-style: normal;
			line-height: 1.35em;
			border-left: 0.25em solid $color-review;
			strong { display: block; font-size: 1rem }
		}

		@include media-breakpoint-up(md) {
			padding: 2.5em 3em;
			img { max-width: 185px; margin: 0 0 0.75em }
		}

		@include media-breakpoint-up(xl) {
			background-size: auto 100%;
		}

	}