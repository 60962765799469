.widget {

	@extend %card;
	margin: 0 0 15px;
	padding: 15px;
	font-size: 85%;
	border: 1px solid #ddd;
	background-color: #fff;

	> *:last-child { margin-bottom: 0; }

	.block-link {
		display: block;
		margin: -15px -15px 15px;
		padding: 0.25em;
		text-align: center;
		> img { display: inline; margin: 0 }
	}

 	&.widget-default { .widget-title { color: $color-text; } }
	&.widget-text { border-color: $color-text; .widget-title { background-color: $color-text; } }
	&.widget-primary { border-color: $color-primary; .widget-title { background-color: $color-primary; } }
	&.widget-secondary { border-color: $color-secondary; .widget-title { background-color: $color-secondary; } }	
	&.widget-highlight { border-color: $color-highlight; .widget-title { background-color: $color-highlight; } }
	&.widget-accent { border-color: $color-accent; .widget-title { background-color: $color-accent; } }
	&.widget-link { border-color: $color-link; .widget-title { background-color: $color-link; } }
	&.widget-review { border-color: $color-review; .widget-title { background-color: $color-review; } }
	&.widget-success { border-color: $color-success; .widget-title { background-color: $color-success; } }
	&.widget-danger { border-color: $color-danger; .widget-title { background-color: $color-danger; } }
	&.widget-info { border-color: $color-info; .widget-title { background-color: $color-info; } }
	&.widget-warning { border-color: $color-warning; .widget-title { background-color: $color-warning; } }
	&.widget-purple { border-color: $purple; .widget-title { background-color: $purple; } }
	&.widget-orange { border-color: $orange; .widget-title { background-color: $orange; } }


	&.widget_blocked { 
		border: 0.25em solid $white;
		.widget-title { background-color: transparent; } 
	}

	&.widget_blocked.widget-text { background-color: $color-text }
	&.widget_blocked.widget-primary { background-color: $color-primary }
	&.widget_blocked.widget-secondary { background-color: $color-secondary }
	&.widget_blocked.widget-highlight { background-color: darken($color-highlight,10%) }
	&.widget_blocked.widget-accent { background-color: $color-accent }
	&.widget_blocked.widget-link { background-color: $color-link }
	&.widget_blocked.widget-review { background-color: $color-review }
	&.widget_blocked.widget-success { background-color: $color-success }
	&.widget_blocked.widget-danger { background-color: $color-danger }
	&.widget_blocked.widget-info { background-color: $color-info }
	&.widget_blocked.widget-warning { background-color: $color-warning }
	&.widget_blocked.widget-purple { background-color: $purple }
	&.widget_blocked.widget-orange { background-color: $orange }
}

	.widget-title:not(.plain) {
		margin: -15px -15px 15px;
		padding: 12px 15px;
		font-size: 1.5rem;
		font-weight: 600;
		letter-spacing: 0.045em;
		background-color: #ddd;
		color: #fff;
	}

		.widget-title.plain { margin: 0 0 5px; }

.widget_plain {
	padding: 0;
	border: none;
	box-shadow: none;
	background-color: transparent;
	.widget-title {
		margin: 0 0 15px;
		padding: 0px;
		background-color: transparent;
		color: inherit;
	}
}

.widget_blocked {
	.widget-title {
		margin: 0;
		padding: 0;
		letter-spacing: 0.075em;
		text-transform: uppercase;
		text-shadow: 0.075em 0.075em 0 rgba(0,0,0,0.2);
	}
}

.widget_icon {
	.icon { width: 96px; margin-bottom: 0.5em }
}


.widget_hours {
	
	li:not(:last-child) {
		margin-bottom: 0.5em !important;
		border-bottom: 1px dashed #ddd;
	}

	li {
		padding: 0 0.25em 0.5em;
		&:last-child { padding-bottom: 0; }
		b {
			display: inline-block;
			width: 115px;
		}
	}

}

.widget-suppliers {
	.outbnd {
		display: inline-block;
		img { margin: 0; }
	}
}