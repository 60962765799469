$banner-bg: 							$white !default;
$banner-color: 							$color-text !default;

.banner {

	padding: 1em 0 2em;
	box-shadow: 0 1px 7px 0 rgba(0,0,0,0.4);
	border-bottom: 5px solid $gray-300;
	background-color: $banner-bg;
	color: $banner-color;
	
	.site_slogan {
		margin: 0;
		font-size: 1.2rem;
		font-style: italic;
		font-family: $font-family-brand;
		line-height: 1em;
	}

	.site_call {
		margin-bottom: 0;
		font-size: 1rem;
		font-weight: 600;
		line-height: 1.1em;
		letter-spacing: -0.025em;
		.phone {
			font-size: 2rem;
			line-height: 1.1em;
		}
	}

	@include media-breakpoint-only(md) {
		padding-bottom: 0;
		background: linear-gradient(to bottom, $white 69%, rgba(255,255,255,0.825) 69%);
		.site_branding,
		.site_contact { padding-bottom: 1em }
	}

	@include media-breakpoint-up(md) {
		.site_contact { text-align: right }		
	}

	@include media-breakpoint-up(lg) {
		padding: 1em 0;
		background-color: rgba(255,255,255,0.9);
		background: linear-gradient(to right, $white 25%, rgba(255,255,255,0.85) 80%);
		.site_call { margin-bottom: 0; }
		.site_navigate .menu_main { text-align: left; }
	}

}