.icon {
	display: inline;
	width: 1.5rem;
	margin: 0;
	vertical-align: middle;
}

	.icon-xs { width: 1rem; }
	.icon-sm { width: 1.25rem; }
	.icon-md { width: 1.5rem; }
	.icon-lg { width: 1.75rem; }
	.icon-xl { width: 2rem; }


	[class*="icon-wrap"] {
		display: inline-block;
		padding-right: 0.5rem;
	}

	[class*="icon-box"] {
		border-sizing: border-box;
		background-color: $color-text;
		padding: 0.5rem;
	}

		.icon-curves { border-radius: 3px; }

	.icon-box--primary { background-color: $color-primary; }
	.icon-box--secondary { background-color: $color-secondary; }
	.icon-box--highlight { background-color: $color-highlight; }
	.icon-box--accent { background-color: $color-accent; }
	.icon-box--text { background-color: $color-text; }
	.icon-box--white { background-color: $white; }
	.icon-box--success { background-color: $color-success; }
	.icon-box--danger { background-color: $color-danger; }
	.icon-box--warning { background-color: $color-warning; }
	.icon-box--info { background-color: $color-info; }