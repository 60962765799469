.block-main {
	padding: 3em 0;
	clear: both;

	.block_title {
		margin-top: 0;
		font-weight: normal;
		text-transform: uppercase;
	}

}


	.card {
		padding: 1em;
		border: 1px solid #ddd;
		background: $white;
		box-shadow: 0 0.25em 1.75em -0.75em rgba(0,0,0,0.7);
	}
