$site_info_bg: 			$color-primary;
$site_info_color: 		$white;

.site_info {

	padding: 2em 0;
	border-top: 0.25em solid $white;
	background-color: $site_info_bg;
	color: $site_info_color;
	font-size: 1rem;
	text-align: center;

	ul { margin-bottom: 0 !important; }

	@include media-breakpoint-up(md) {
		h4.info_title { margin-top: 0.75em; }
	}

	@include media-breakpoint-up(lg) {
		h4.info_title { margin-top: 0; }
	}

}

h4.info_title {
	margin: 1.25em 0 0.25em;
	font-size: 1.2rem;
	font-weight: normal;
	text-transform: uppercase;
}

	.info_company {
		line-height: 1.4em;
	
		h4.info_title {
			//display: none;
			margin: 0;
		}

		.phone {
			font-size: 1.6rem;
			line-height: 1.4em;
			color: $white;
		}

		.company_address {
			margin: 0.25em 0;
			line-height: 1.1em;
		}

			.company_street { display: block }

		.company_license {
			small, b { display: block }
		}

	}

	.icon_list {
		li { display: inline-block; margin-right: 0.5em; }
			a {
				text-decoration: none;
				&:hover img { transform: scale(1.15); @include transition(all ease-in-out 200ms) }
			}
			img { width: 48px; @include transition(all ease-in-out 200ms) }
			small { display: none }

		.info_pay & img { width: 60px; }

	}