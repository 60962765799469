@font-face {
    font-family: 'OPTIJake-Antique';
    src:url('/_/fonts/optijake-antique/OPTIJake-Antique.ttf.woff') format('woff'),
        url('/_/fonts/optijake-antique/OPTIJake-Antique.ttf.svg#OPTIJake-Antique') format('svg'),
        url('/_/fonts/optijake-antique/OPTIJake-Antique.ttf.eot'),
        url('/_/fonts/optijake-antique/OPTIJake-Antique.ttf.eot?#iefix') format('embedded-opentype'); 
    font-weight: normal;
    font-style: normal;
}

html {

	font-size: 15px;
	line-height: 1.4em;
	-webkit-text-size-adjust: 100%;

	@include media-breakpoint-up(md) { font-size: 16px; line-height: (1.4 / 15) * 16em	}
	@include media-breakpoint-up(lg) { font-size: 17px; line-height: (1.4 / 15) * 17em }

}

body {

	font-size: 1rem;
	font-family: $font-family-base;

}

h1, h2, h3, h4, h5, h6 {
	margin: 1.25em 0 0.25em;
	font-family: $font-family-brand;
	font-weight: normal;
	line-height: 1em;
	//text-transform: uppercase;
	letter-spacing: 0.025rem;
}

h6 { font-size: 1.2rem; }

h5 { font-size: 1.2rem; }

h4 {
	font-size: 1.4rem;
	@include media-breakpoint-up(md) { font-size: 1.6rem; }
	@include media-breakpoint-up(lg) { font-size: 1.675rem; }
}

h3 {
	font-size: 1.6rem;
	font-weight: bold;
	@include media-breakpoint-up(md) { font-size: 1.8rem; }
	@include media-breakpoint-up(lg) { font-size: 1.85rem; }
}

h2 {
	font-size: 1.8rem;
	@include media-breakpoint-up(md) { font-size: 2.0rem; }
	@include media-breakpoint-up(lg) { font-size: 2.1rem; }
}

h1 {
	font-size: 2.0rem;
	font-weight: bold;
	@include media-breakpoint-up(md) { font-size: 2.2rem; }
	@include media-breakpoint-up(lg) { font-size: 2.4rem; }
}

p {
	margin: 0 0 1em;
	&.lead {
		font-size: 120%;
		line-height: (1.5 * 1.02) * 1em;
	}
}

ul, ol {
	margin: 0 0 1em;
	padding: 0 0 0 1.2em;
	list-style-type: square;
}

.b0 { font-weight: 400; }
b, strong, .b1 { font-weight: 600; }
.bolder, .strong, .b2 { font-weight: 800; }

address {
	margin-bottom: 1em;
	font-style: normal;
}

blockquote {

	margin: 0 0 1.5em;
	padding: 0.5em 0.5em 0.5em 1em;
	font-size: 1.15rem;
	border-left: 0.5rem solid;

	>*:last-child { margin-bottom: 0; }

	&.bq--text { border-color: $color-text; }
	&.bq--primary { border-color: $color-primary; }
	&.bq--secondary { border-color: $color-secondary; }
	&.bq--highlight { border-color: $color-highlight; }
	&.bq--accent { border-color: $color-accent; }
	&.bq--success { border-color: $color-success; }
	&.bq--danger { border-color: $color-danger; }
	&.bq--warning { border-color: $color-warning; }
	&.bq--info { border-color: $color-info; }

	&.review {
		padding: 0;
		margin: 0 0 1em;
		font-size: 1rem;
		border: none;
	}
}