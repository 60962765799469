.spotlight {

	border-top: 5px solid $color-text;
	border-bottom: 5px solid $color-text;
	background-color: $white;
	@include gdfancy-bg($gray-100);

	@include media-breakpoint-up(md) {
		padding: 3em 0;
		.row {align-items: center; justify-content: center;}
		.spot_details { @include make-col(6) }
		.spot_gallery { @include make-col(6) }
	}

	@include media-breakpoint-up(lg) {
		.spot_details { @include make-col(5) }
		.spot_gallery { @include make-col(5) }
	}

}

	.spot_gallery {

		.gallery_show {
			
			overflow: hidden;

			li {
				display: block;
				float: left;
				width: (94 / 4) * 1%;

				&:nth-child(3) { margin: 0 2% 1.5%; }
				&:nth-child(4) { margin: 0 2% 1.5% 0; }

				img {
					display: block;
					width: 100%;
					margin: 0;
					padding: 0.175em;
					border: 1px solid $color-text;
				}

				&:first-child {
					float: none;
					width: 100%;
					margin-bottom: 1.5%;
					img {
						border: 1px solid $color-text;
						margin-bottom: 0.5em;
						padding: 0.25em;
					}
				}

				&:last-child {
					@include transition(all ease-in-out 300ms);
					background-image: url("/_/images/spotlight/span-link.png");
					background-size: cover;
					&:hover {
						@include transition(all ease-in-out 300ms);
						background-image: url("/_/images/spotlight/span-hover.png");
					}
				}

			}

		}

		.gallery_more { display: none; }

	}


	.spot_details {
		ul {
			li:not(:last-child) {
				margin-bottom: 0.25em;
			}
		}
		@include media-breakpoint-up(md) {
			ul { margin-bottom: 0 }
		}
	}