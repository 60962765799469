.jumbo {

	padding: 0;
	//border-bottom: 4px solid $white;
	border-bottom: 5px solid $gray-400;
	background-color: $white;
	background-color: $gray-800;

	@include media-breakpoint-up(md) { margin-top: -65px }

	@include media-breakpoint-up(lg) {
		margin-top: 0;
		.item img { max-height: 575px; }
	}

	@include media-breakpoint-up(xl) {
	}

	@media screen and (min-width: 1400px) { 
		.item img {
			display: block;
			width: 100%;
            max-width: 1400px;
            margin: 0 auto;
            //border-left: 5px solid $white;
            //border-right: 5px solid $white;
            box-shadow: 0 0 6em 0.25em rgba(255, 255, 255, 0.5);
            box-shadow: 0 0 8em 0.25em rgba(0,0,0,1);
		}
	}

}