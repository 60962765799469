.site-alert {

	border-top: 0.25em solid $white;
	text-align: center;

	.wrap {
		> :first-child { margin-top: 0 }
		> :last-child { margin-bottom: 0 }
	}

	p {
		font-size: 1.3rem;
		line-height: 1em;
		> span {
			display: block;
			&:not(:last-child) { margin-bottom: 0.5em; }
		}
		strong { display: block; }
	}

		.alert_button { font-size: 1.1rem; }

	@include media-breakpoint-up(sm) {
		p {
			@include make-row;
			align-items: center;
			justify-content: center;
			> span { margin-bottom: 0 !important }
		}
			.alert_icon { @include make-col(2) }
			.alert_text { @include make-col(5); text-align: left; }
			.alert_button { @include make-col(3); }		
	}

	@include media-breakpoint-up(md) {
		p { font-size: 1.5rem; }
			.alert_icon img { width: 3.5rem; }
			.alert_button {
				@include make-col(5);
				.btn {
					display: block;
					float: right;
					width: 220px;
					margin-top: 4px;
					text-align: center;
				} 
			}
	}

	@include media-breakpoint-up(lg) {
		padding: 0.75em 0;
		p { 
			strong { display: inline; }
			.alert_icon { @include make-col(2); text-align: right; padding-right: 0.5em }
				.alert_icon img { margin: 0; }
			.alert_text { @include make-col(7); }
			.alert_button { @include make-col(3); .btn { float: left } }
		}
	}

	@include media-breakpoint-up(xl) {
		p {	font-size: 1.7rem;}
			.alert_icon { @include make-col(1) }
				.alert_icon img { width: 3rem; }
			.alert_text { @include make-col(4); padding-left: 0.5em }
			.alert_button { @include make-col(7); }		
	}

}