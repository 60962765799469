.amenities {

	padding: 0;
	text-align: center;
	border-top: 0.25em solid $white;
	background: linear-gradient(to bottom, $indigo 50%, $orange 50%);
	color: $white;

	p {
		margin: 0;
		padding: 1em 2em;
		font-size: 1.2rem;
		font-weight: 800;
		line-height: 1em;
		text-transform: uppercase;
		text-shadow: 0.095em 0.095em 0 rgba(0,0,0,0.2);
		img {
			display: block;
			margin: 0 auto 0.5em;
			width: 3em;
		}
	}

	@include media-breakpoint-up(sm) {
		background: linear-gradient(to right, $indigo 50%, $orange 50%);
		.row { align-items: center; justify-content: center; }
		.amenity_delivery { @include make-col(6) }
		.amenity_wrapping { @include make-col(6) }
	}

	@include media-breakpoint-up(md) {
		p { padding: 0.75em 2em; }
	}

}