.site_branding {}

	.branding_logo {
		display: block;
		width: 100%;
		max-width: 360px;
		margin: 0 auto;

		&:hover {}

		@include media-breakpoint-up(lg) {
/* 			max-width: 280px;
			margin: 0;
 */		}

	}