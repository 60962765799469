$navbar-count: 			6;

[class*="nav_bar"] {

	.nav_menu > .menu_item {
		
		display: inline-block;
		font-size: 15px;
		font-weight: 600;
		text-transform: uppercase;
		text-align: center;

		.menu_link { padding: 1em; }

	}

		.dropdown_menu {
			position: absolute;
			z-index: 99000;
			text-transform: none;
			font-weight: 400;
		}	

}

	@if ($navbar-count) {
		.nav_bar--blocked {
			.nav_menu > .menu_item {
				display: block;
				float: left;
				width: percentage(1/$navbar-count);
			}			
		}
	}