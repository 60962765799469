
.form_review {

	padding: 0 0 0.75em;
	border: 1px solid #e5e5e5;
	box-shadow: 0 0.25em 1.75em -0.75em rgba(0,0,0,0.7);
	background-color: #fff;

	.form_title {
		margin: 0.5em 0.3em 0.25em;
		padding: 0;
		font-size: 2.2rem;
		line-height: 1em;
	}

}

.form_rating {

	overflow: hidden;
	padding: 0 0.75em 0.1em;

	strong {
 		display: inline-block;
		float: left;
		padding: 10px 5px 10px 0;
	}

	.rating {
 		display: inline-block;
 		padding: 8px 0 7px;
		float: left;
		overflow: hidden;
	}

}

	.rating_control {
		display: block;
		float: right;
		width: 26px;
		height: 24px;
		padding: 0;
		margin: 0 5px 0 -26px;
		opacity: 0;
	}

	.rating_star {
		cursor: pointer;
		position: relative;
		display: inline-block;
		float: right;
		width: 26px;
		height: 24px;
		text-indent: -9999px;
		background-image: url('/_/images/icons/stars/form-stars.svg');
		background-size: cover;
	}

	.rating:hover .rating_star:hover,
	.rating:hover .rating_star:hover ~ .rating_star,
	.rating_control:checked ~ .rating_star {
	    background-position: 0 -24px;
	}
