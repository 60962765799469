$contact_title_color: 				$color-primary;
$contact_phone_color: 				$color-primary;

.site_contact {}

	.contact_phone {
		font-size: 2rem;
		font-weight: 600;
		line-height: 1em;
		letter-spacing: -0.04em;
		color: $contact_phone_color;
		&:hover { color: $contact_phone_color; outline: none; }
		@include media-breakpoint-up(md) {
			font-size: 2rem;
			text-align: right;
		}
	}