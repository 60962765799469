$welcome-bg: 			$white; // transparent; //$color-primary;
$welcome-color:			$color-text; //$white;

.welcome {

	padding: 3em 0 2.75em;
	text-align: center;
	background-color: $welcome-bg;
	color: $welcome-color;

	.block_title {
		margin: 0 0 1em;
		letter-spacing: 0.125em;
		small {
			display: block;
			max-width: 600px;
			margin: 0.25em auto 0;
			padding: 0.25em 0 0;
			border-top: 1px solid rgba(255,255,255,0.3);
			border-top: 1px solid $gray-400;
			font-size: 70%;
			line-height: 1.1em;
		}
	}

	p {
		max-width: 720px;
		margin-left: auto;
		margin-right: auto;
	}

}