$copyright-bg:			$color-text;
$copyright-color: 		$white;

.copyright {

	border-top: 0.25em solid $white;
	background-color: $copyright-bg;
	color: $white;
	a { color: $white; }
	
}

	.info_copyright {

		ul > li { margin-bottom: 0 !important; }

		@include media-breakpoint-up(md) {
			ul > li {
				display: inline-block;
				&:not(:last-child) { margin-right: 1em; }
			}
		}

	}

	