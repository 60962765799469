$socials-bg: 			$white;

.socials {

	background-color: $socials-bg;

	@include media-breakpoint-up(sm) {
		.row { align-items: center; justify-content: center; }
		.social_icons { @include make-col(6); }
		.social_contact { @include make-col(6); margin-top: 0 }
	}

	@include media-breakpoint-up(md) {
		.social_icons { @include make-col(8); text-align: left; }
		.social_contact { @include make-col(4); text-align: right; }
	}
	
	.page_front & {
		.social_icons {
			@include media-breakpoint-up(md) {
				@include make-col(12);
				text-align: center;
			}
		}
	}

}

	.social_icons {

		ul { margin: 0;	}

			li { display: inline-block; }

			li:not(:last-child) { margin-right: 1em; }
			
			li.socials_title {
				display: block;
				margin-right: 0;
				font-size: 1.2rem;
				font-weight: 800;
				letter-spacing: -0.05em;
				text-transform: uppercase;
			}


		a { 
			text-decoration: none;
			text-transform: uppercase;
			color: $color-text;
			small { display: none; }
			.icon {
				@include transition(all ease-in-out 200ms);
				width: 48px;
			}
			&:hover {
				.icon {
					transform: scale(1.075);
					@include transition(all ease-in-out 200ms);
				}
			}
		}

		@include media-breakpoint-up(md) {
			li { margin-bottom: 0 !important; }
			li.socials_title {
				display: inline-block;
				margin-right: 2em;
			}
		}

	}


	.social_contact {

		margin-top: 1em;
		text-align: center;

		.phone {
			font-size: 1.8rem;
			font-weight: 600;
		}

	}