$mm_bg: 						transparent; //$white !default;

$mm_link_bg:					rgba(255,255,255,0.2); // transparent; // $color-primary;
$mm_link_color:					$color-text; // $white;
$mm_hover_bg:					rgba(255,255,255,0.4); // transparent; // lighten($color-primary, 7.5%);
$mm_hover_color:				$color-primary; //darken($color-primary,10%); //$color-text;
$mm_after_color:				$color-primary; //darken($color-secondary, 10%);
$mm_active_bg:					$color-primary; //transparent; // rgba($white, 0.6); //transparent; //$color-secondary;
$mm_active_color:				$white; // $color-accent; //darken($color-primary,7.5%);

$mm_dropdown_link_bg: 			$mm_hover_bg;
$mm_dropdown_link_color: 		$white;
$mm_dropdown_hover_bg: 			rgba(255,255,255,0.27); //lighten($color-primary, 12.5%);
$mm_dropdown_hover_color: 		$white;
$mm_dropdown_active_bg: 		$white;
$mm_dropdown_active_color: 		$color-primary;
$mm_dropdown_divider_dark: 		1px solid rgba(0,0,0,0.18);
$mm_dropdown_divider_lite:		1px solid rgba(255,255,255,0.22);

.menu_main {

	background-color: $mm_bg;

	.menu_item {

		display: inline-block;
		padding: 0.5em 0;

		&:hover { .menu_link:after { width: 100%; @include transition(all ease-in-out 300ms) } }

		&.open {
			> .menu_link {
				@if $mm_hover_bg { background-color: $mm_hover_bg !important; }
				@if $mm_hover_color { color: $mm_hover_color !important; }
				outline: none;
			}
			.dropdown_menu {
				background-color: $mm_hover_bg;
			}
		}

		&.active {
			&:after { background-color: transparent !important; }
			&:hover { .menu_link:after { width: 0%; transition: none; } }
			.menu_link {
				outline: none;
				background-color: $mm_active_bg !important;
				background: $mm_active_bg !important;
				color: $mm_active_color !important;			
			}
		}

		@include media-breakpoint-up(lg) {
			margin: 0.25em 0 0;
			padding: 0;
		}

	}

		.menu_link {
			padding: 0.9em 1.25em 0.5em;
			font-size: 15px;
			font-weight: 600;
			letter-spacing: -0.02em;
			text-transform: uppercase;
			border-radius: 4px;
			background-color: $mm_link_bg;
			color: $mm_link_color;
			&:hover, &:focus {
				outline: none;
				background-color: $mm_hover_bg;
				color: $mm_hover_color;
			}
			&:after {
				@include transition(all ease-in-out 300ms);
				display: block;
				width: 0;
				height: 3px;
				margin: 2px auto 0;
				background-color: $mm_after_color;
				content: "";
			}			
		}

		.dropdown_menu {

			.menu_item {
				&:not(:last-child) { border-bottom: $mm_dropdown_divider_dark; }
				&:not(:first-child) { border-top: $mm_dropdown_divider_lite; }
				&.active .menu_link {
					outline: none;
					background-color: $mm_dropdown_active_bg !important;
					color: $mm_dropdown_active_color !important;
				}
			}

				.menu_link {
					background-color: $mm_dropdown_link_bg;
					color: $mm_dropdown_link_color;
					&:hover, &:focus {
						outline: none;
						background-color: $mm_dropdown_hover_bg;
						color: $mm_dropdown_hover_color;					
					}
				}

		}

}