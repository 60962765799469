$announce-bg: 				$color-text !default;
$announce-color: 			$white !default;

.announce {

	padding: 1em 0;
	
	text-align: center;

	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.5);
	background-color: $announce-bg;
	border-bottom: 0.25rem solid $white;
	color: $announce-color;

	.announce_call {
		margin-bottom: 0.5em;
		font-size: 1.75rem;
		font-weight: 600;
		line-height: 1em;
	}

	.announce_contact {
		font-size: 1.25rem;
		font-weight: 600;
		text-transform: uppercase;
	}

	@include media-breakpoint-up(sm) {
		background: linear-gradient(45deg, $color-text, darken($color-text, 15%) );
		.announce_call { margin-bottom: 0 }
	}

}