@mixin btn-reg($bg: #bbb, $color: $white ) {
    display: inline-block;
    padding: 0.5em 1em;
    text-decoration: none;
    border: 1px solid darken($bg, 12.5%);
    background-color: $bg;
    color: $color;
    @include transition(all ease-in-out 200ms);
    &:hover {
        background-color: lighten($bg, 12.5%);
        color: $color;
        @include transition(all ease-in-out 200ms);
    }    
}


[class*="btn"] {
	@include btn-reg;
	border-radius: 3px;
	cursor: pointer;

	&:focus {
		outline: 4px solid rgba(0,0,0,0.25);
	}

}

	%btn_link, .btn_link { @include btn-reg($color-link) }
	%btn_primary, .btn_primary { @include btn-reg($color-primary) }
	%btn_secondary, .btn_secondary { @include btn-reg($color-secondary) }
	%btn_highlight, .btn_highlight { @include btn-reg($color-highlight) }
	%btn_accent, .btn_accent { @include btn-reg($color-accent) }
	%btn_success, .btn_success { @include btn-reg($color-success) }
	%btn_danger, .btn_danger { @include btn-reg($color-danger) }
	%btn_info, .btn_info { @include btn-reg($color-info) }
	%btn_review, .btn_review { @include btn-reg(lighten($color-review,5%)) }
	%btn_warning, .btn_warning { @include btn-reg($color-warning) }

	%btn-block, .btn-block { display: block; text-align: center; }

	.btn_close {
		float: right;
		margin: 0 0 0 15px;
		padding: 5px;
		font-size: 24px;
		line-height: 1em;
		text-align: center;
		border-radius: 3px;
		border: 1px solid darken($color-danger, 15%);
		background-color: $color-danger;
		color: #fff;
		opacity: 0.8;
		cursor: pointer;
		.close_img {
			width: 0.75rem;
		}
		&:hover {
			background-color: darken($color-danger, 20%);
		}
	}

	%btn--sm, .btn--sm {
		padding: 0.2em 0.75em;
		font-size: 0.85rem;
	}

	%btn--wide, .btn--wide {
		display: block;
	}